import { Form, Formik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loginApi } from "../../../../Services/AuthApi";
import { useNavigate } from "react-router-dom";
import "./loginForm.css";
import { InputField, PasswordField } from "../../../../Component/InputField/InputField";

export const LoginForm = () => {
  const errorNotify = (msg) => toast.error(msg);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const initialValue = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().email().required("Email is required"),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{8,}$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
      )
      .required("Password is required"),
  });

  //set token in localStorage
  const setItem = (response) => {
    localStorage.setItem("token", response.data.data.token);
    localStorage.setItem("profile", JSON.stringify(response.data.data));
  };

  //Call login api
  const onSubmit = (values) => {
    setLoading(true);
    loginApi(values)
      .then((response) => {
        console.log("login--",response);
        if (response.data.data.role === "super_admin") {
          setItem(response);
          navigate("/group");
        } else {
          errorNotify("You don't have the access, please contact Admin");
          setLoading(false);
        }
      })
      .catch((errMsg) => {
        errorNotify(errMsg.response.data.message);
        setLoading(false);
      });
  };

  return (
    <>
      <Formik
        initialValues={initialValue}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <div className="create-password">
                <h1>SIGN IN</h1>
                <p>Enter Your Credentials to access your account</p>
              </div>
              <div className="input-container">
                <InputField
                  name="email"
                  label="Email"
                  placeholder="Email"
                  type="email"
                />
              </div>
              <div className="input-container">
                <PasswordField
                  name="password"
                  label="Password"
                  placeholder="Password"
                  showPassword={showPassword}
                  onClick={() => setShowPassword(!showPassword)}
                />
              </div>
              <div className="button-container">
                <Button type="submit" disabled={loading}>
                  {loading && (
                    <Spinner
                      as="span"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  {loading ? " loading.... " : "SIGN IN"}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
