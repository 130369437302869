import React from "react";
import "./addCompany.css";
import { useNavigate } from "react-router-dom";
import { AddForm } from "./AddForm/AddForm";
export const AddCompany = () => {
  const navigate = useNavigate();
  return (
    <div className="inner-contain">
      <div className="container-fluid">
        <div className="row">
          <div className="d-flex">
            <p
              className="px-1 group-component"
              onClick={() => navigate("/group")}
            >
              Group
            </p>
            {"/"}
            <p className="add px-1">Add new Group</p>
          </div>
          <h4>Add New Group</h4>
          <p className="notice mb-0">
            Please fill the information to add the group in the system
          </p>
          <div className="col p-3">
            <div className="row add-container">
              <div className="col-12 p-4">
                <AddForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
