import React from 'react'
import './formLayout.css'
import auth_pattern from "../../Assets/Images/auth_pattern.png"
import logo from "../../Assets/Images/logo_1x.png"
export const FormLayout = ({component,headerTitle,subTitle}) => {
    return (
        <div className='bg-white'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12 col-lg-6 col-xl-5 p-0 m-0'>
                        <div className='form-inner-container'>
                            <img src={logo} alt='logo' />
                            <div className='form-text-container'>
                                <h2>{headerTitle}</h2>
                                <h6>{subTitle}</h6>
                            </div>
                            <div className='auth-bg'></div>
                        </div>
                    </div>
                    <div className='col-12 col-lg-6 col-xl-7 p-0 m-0 form-container'>
                        <div className='auth-form'>
                           {component}
                        </div>
                        <div className='auth-img'>
                            <img src={auth_pattern} alt='auth_pattern' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
