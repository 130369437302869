import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import "./editCompany.css";
import { EditForm } from "./EditForm/EditForm";
import { groupProfileApi } from "../../../../Services/CompanyApi";
import { Loader } from "../../../../Component/Loader/Loader";

export const EditCompany = () => {
  // Define state variables
  const navigate = useNavigate();
  const useParamId = useParams();
  const [profileData, setProfileData] = useState();
  const [loading, setLoading] = useState(true);

  //Call company profile api
  useEffect(() => {
    groupProfileApi(useParamId.group_id)
      .then((response) => {
        setProfileData(response.data.data[0]);
        setLoading(false);
      })
      .catch((error) => console.log(error.response.data.message));
  }, []);

  return (
    <>
      {loading ? (
        <div className="loader">
          <Loader />
        </div>
      ) : (
        <div className="inner-contain">
          <div className="container-fluid">
            <div className="row">
              <div className="d-flex">
                <p
                  className="px-1"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/group")}
                >
                  Group
                </p>
                {"/"}
                <p className="add px-1">Group Update</p>
              </div>
              <h4>Group update</h4>
              <p className="notice mb-0">
                Please fill the information to update the group in the system
              </p>
              <div className="col p-3">
                <div className="row add-container">
                  <div className="col-12 p-4">
                    {/* Render the EditForm component with editData */}
                    {profileData && <EditForm editData={profileData} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
