import { Api } from "../Api/Api";

//Group list api
export const getGroupsList = () => Api.get("api/getGroupsList");

//Add group api
export const addGroupApi = (data) => Api.post("api/groupRegister", data);

//Group profile api
export const groupProfileApi = (group_id) =>
  Api.get(`api/getGroupsList?group_id=${group_id}`);

// Remove group api
export const removeGroupApi = (group_id) =>
  Api.delete(`api/groupDelete?group_id=${group_id}`);
