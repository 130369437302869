import React from 'react'
import { FormLayout } from '../../../Component/FormLayout/FormLayout'
import { LoginForm } from './LoginForm/LoginForm'

export const Login = () => {
  return (
    <FormLayout
      headerTitle={'Get started Super Admin'}
      subTitle={'Welcome, please login to your account'}
      component={<LoginForm />}
    />
  )
}
