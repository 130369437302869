import { RouterProvider } from "react-router-dom";
import "./App.css";
import { router } from "./Routes";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Assets/Css/custom.css";
import { Provider } from "react-redux";
import store from "./Redux/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  return (
    <>
      <ToastContainer />
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </>
  );
}

export default App;
