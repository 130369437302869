import React from "react";
import { NavLink } from "react-router-dom";
import "./sidebar.css";
import work from "../../Assets/Images/work.png";
import workActive from "../../Assets/Images/work-fill.png";

export const Sidebar = () => {
  return (
    <div className="side-bar">
      <div className="barContainer">
        <NavLink
          to="group"
          className={({ isActive }) =>
            isActive ? "sidebar-active" : "sidebar-pending"
          }
        >
          {({ isActive }) => (
            <img src={isActive ? workActive : work} alt="company" />
          )}
        </NavLink>
      </div>
    </div>
  );
};
