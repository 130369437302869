import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getGroupsList } from "../Services/CompanyApi";

const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});
export const companySlice = createSlice({
  name: "company",
  initialState: {
    data: [],
    status: STATUSES.IDLE,
  },
  reducers: {
    addCompany: (state, action) => {
      return {
        ...state,
        data: [...state.data, action.payload],
      };
    },
    removeCompany: (state, action) => {
      const companyId = action.payload;
      const newData = state.data.filter((item) => item.group_id !== companyId);
      return {
        ...state,
        data: newData,
      };
    },
    editCompany: (state, action) => {
        
      const editData = state.data.map((item) =>
        item.group_id === action.payload.group_id ? action.payload : item
      );
      return {
        ...state,
        data: editData,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        state.data = action.payload;
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});

export const { setUser, setStatus, addCompany, editCompany, removeCompany } =
  companySlice.actions;
export default companySlice.reducer;

export const fetchUserData = createAsyncThunk(
  "user/fetchUserData",
  async (params, thunkAPI) => {
    try {
      const response = await getGroupsList();
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);
