import React, { useState } from "react";
import { Formik, Form as FormikForm } from "formik";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import "./editForm.css";
import { statusData } from "../../../../../Services/Constant/ConstantOptions";
import { addGroupApi } from "../../../../../Services/CompanyApi";
import { useDispatch } from "react-redux";
import { editCompany } from "../../../../../Redux/companySlice";
import { InputField } from "../../../../../Component/InputField/InputField";

export const EditForm = ({ editData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const initialValue = {
    group_id: editData.group_id,
    name: editData.name,
    address: editData.address,
    website_url: editData.website_url,
    contact_person_name: editData.contact_person_name,
    status: editData.status,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Group name is required"),
    address: Yup.string().required("Address is required"),
    website_url: Yup.string()
      .matches(
        /^www\.[a-zA-Z0-9]+\.[a-zA-Z]+$/,
        'Invalid website format. Must be "www.example.com"'
      )
      .required("Website URL is required"),
    contact_person_name: Yup.string().required(
      "Contact person name is required"
    ),
  });

  const onSubmit = (values) => {
    setLoading(true);
    if (JSON.stringify(values) !== JSON.stringify(initialValue)) {
      const formData = new FormData();
      Object.entries(values).forEach(([key, value]) => {
        formData.append(key, value);
      });

      addGroupApi(formData)
        .then((response) => {
          dispatch(editCompany(values));
          toast.success(response.data.message);
          navigate("/group");
        })
        .catch((errMsg) => {
          //console.log(errMsg.response.message)
          setLoading(false);
          toast.error(errMsg.response.data.message);
        });
    } else {
      setLoading(false);
      navigate("/group");
    }
  };

  return (
    <Formik
      initialValues={initialValue}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, setFieldValue }) => {
        console.log("values", values);
        return (
          <FormikForm>
            <div className="row">
              <div className="col-4">
                <InputField
                  name="name"
                  label="Group name"
                  placeholder="Group name"
                  type="text"
                />
              </div>
              <div className="col-4">
                <InputField
                  name="address"
                  label="Address"
                  placeholder="Address"
                  type="text"
                />
              </div>
              <div className="col-4">
                <InputField
                  name="website_url"
                  label="Website"
                  placeholder="Website"
                  type="text"
                />
              </div>
              <div className="col-4">
                <InputField
                  name="contact_person_name"
                  label="Contact person name"
                  placeholder="Contact person name"
                  type="text"
                />
              </div>

              <div className="col-4 mx-1 add-cmp-form">
                <Select
                  options={statusData}
                  placeholder="Status"
                  value={
                    values.status
                      ? statusData.find(
                          (options) => options.value === values.status
                        )
                      : ""
                  }
                  onChange={(option) => setFieldValue("status", option.value)}
                />
              </div>

              <div className="col-12">
                <div className="d-flex mt-4 flex-row-reverse">
                  <Button
                    variant="primary"
                    type="submit"
                    className="px-4 mx-1 add-button"
                  >
                    {loading && (
                      <Spinner
                        as="span"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    {loading ? " loading.... " : "Submit"}
                  </Button>
                  <Button
                    variant="outline-secondary"
                    className="px-4 mx-1"
                    onClick={() => navigate("/group")}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
