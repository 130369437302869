import React, { useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./Header.css";
import logo from "../../Assets/Images/logo.png";
import logoutIcon from "../../Assets/Images/logout.png"
import profile_icon from "../../Assets/Images/profile-icon.png"
import logout_icon from "../../Assets/Images/logout-icon.png"
//import bell from "../../Assets/Images/bell.svg";
//import { SideCanvas } from "../Offcanvas/SideCanvas";
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from "react-router-dom";
import { PopupModal } from "../PopupModal/PopupModal";

const Header = () => {
  const navigate = useNavigate()
  // const [show, setShow] = useState(false);
  const [showPopup, setShowPopup] = useState(false)
  // const handleShow = () => { setShow(true) }
  // const handleClose = () => { setShow(false) }
  const adminData = localStorage.getItem("profile")
  const profilePhoto = adminData ? JSON.parse(adminData) : adminData
  //console.log("profile",profilePhoto[0]?.upload_photo)
  const handleLogOut =()=>{
    localStorage.clear()
    navigate('/')
  }
  return (
    <>
        <div className="navigation">
          <Navbar collapseOnSelect expand="lg">
            <Navbar.Brand>
              <img src={logo} alt='logo' />
            </Navbar.Brand>
            <Nav>
              <div className="title">
                <h6 className="p-0 m-0">Super Admin</h6>
              </div>
              {/* <div className="navigation-icon" onClick={handleShow}>
                <a href="#">
                  <img src={bell} alt='bell' />
                </a>
              </div> */}
              <div className="admin-profile-dropdown">
                <Dropdown>
                  <Dropdown.Toggle variant="success" className='profile rounded-circle' id="dropdown-basic">
                    {profilePhoto?.upload_photo && <img src={profilePhoto?.upload_photo}/>}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {/* <Dropdown.Item><img src={profile_icon} alt='bell' className="mb-1 me-1"/>Profile</Dropdown.Item> */}
                    <Dropdown.Item onClick={()=>setShowPopup(true)}><img src={logout_icon} alt='bell' className="mb-1 me-1"/>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Nav>
          </Navbar>
        </div>
      {/* <SideCanvas show={show} handleClose={handleClose} /> */}
      {showPopup && <PopupModal show={showPopup} modalTitle={'Logout'} description={'Are you sure you want to logout'} handleClose={()=>setShowPopup(false)} photo={logoutIcon} button1={'Cancel'} button2={'Confirm'} handleDelete={handleLogOut}/>}
    </>
  );
};
export default Header;
