import React, { useState } from "react";
import { ErrorMessage, Field, Formik, Form as FormikForm } from "formik";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import InputMask from "react-input-mask";

import imageIcon from "../../../../../Assets/Images/image.png";
import { addCompany } from "../../../../../Redux/companySlice";
import { statusData } from "../../../../../Services/Constant/ConstantOptions";
import { addGroupApi } from "../../../../../Services/CompanyApi";
import "./addForm.css";
import {
  InputField,
  PasswordField,
} from "../../../../../Component/InputField/InputField";

export const AddForm = () => {
  // Define state variables
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [imageUrl, setImageUrl] = useState("");
  const [imageError, setImageError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // Define initial form values
  const initialValue = {
    name: "",
    email: "",
    phone: "",
    address: "",
    website_url: "",
    contact_person_name: "",
    password: "",
    status: "",
  };

  // Define form validation schema
  const validationSchema = Yup.object({
    name: Yup.string().required("Group name is required"),
    email: Yup.string().email().required("Email address is required"),
    phone: Yup.string().required("Phone number is required"),
    address: Yup.string().required("Address is required"),
    website_url: Yup.string()
      .matches(
        /^www\.[a-zA-Z0-9]+\.[a-zA-Z]+$/,
        'Invalid website format. Must be "www.example.com"'
      )
      .required("Website URL is required"),
    contact_person_name: Yup.string().required("Enter contact person name"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
      ),
    status: Yup.string().required("Status is required"),
  });

  // Check image validation and set state variables accordingly
  const checkImageValidation = (image_obj) => {
    setImageUrl(image_obj);
    if (image_obj && image_obj.size > 5000000) {
      setImageError(
        "Please ensure that the image you are uploading is less than 5MB in size"
      );
    } else {
      setImageError("");
    }
  };

  const checkImageSizeValidation = () => {
    setImageUrl("");
    setImageError("");
  };

  // Handle form submission
  const onSubmit = (values) => {
    setLoading(true);
    const formData = new FormData();
    // ... (prepare form data)
    Object.entries(values).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append("logo_url", imageUrl);
    // Make API call to add group
    addGroupApi(formData)
      .then((response) => {
        // ... (dispatch action and show success message)
        dispatch(addCompany(response.data.data));
        toast.success(response.data.message);
        navigate("/group");
      })
      .catch((err_msg) => {
        // ... (show error message)
        toast.error(err_msg.response.data.message);
        setLoading(false);
      });
  };

  return (
    <Formik
      initialValues={initialValue}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue }) => {
        return (
          <FormikForm>
            <div className="row">
              <div className="col-4 add-company-input-flied">
                <InputField
                  name="name"
                  label="Group name"
                  placeholder="Group name"
                  type="text"
                />
              </div>
              <div className="col-4 add-company-input-flied">
                <InputField
                  name="email"
                  label="Group email"
                  placeholder="Group email"
                  type="email"
                />
              </div>
              <div className="col-4">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Phone Number"
                  className="mb-3 add-company-input-flied"
                >
                  <Field name="phone">
                    {({ field }) => (
                      <InputMask mask="(999) 999-9999" {...field}>
                        {() => (
                          <Form.Control
                            autoComplete="off"
                            type="text"
                            placeholder="Phone Number"
                            {...field}
                          />
                        )}
                      </InputMask>
                    )}
                  </Field>
                  <ErrorMessage
                    name="phone"
                    render={(errMsg) => (
                      <div className="errorMessage">{errMsg}</div>
                    )}
                  />
                </FloatingLabel>
              </div>
              <div className="col-4 add-company-input-flied">
                <InputField
                  name="address"
                  label="Address"
                  placeholder="Address"
                  type="text"
                />
              </div>
              <div className="col-4 add-company-input-flied">
                <InputField
                  name="website_url"
                  label="Website"
                  placeholder="Website"
                  type="text"
                />
              </div>
              <div className="col-4 add-company-input-flied">
                <InputField
                  name="contact_person_name"
                  label="Contact person name"
                  placeholder="Contact person name"
                  type="text"
                />
              </div>
              <div className="col-4 add-company-input-flied">
                <PasswordField
                  name="password"
                  label="Password"
                  placeholder="Password"
                  showPassword={showPassword}
                  onClick={() => setShowPassword(!showPassword)}
                />
              </div>

              {/* Render select status section */}
              <div className="col-4 mx-1 add-cmp-form">
                <Select
                  options={statusData}
                  placeholder="Status"
                  value={
                    values.status
                      ? statusData.find(
                          (options) => options.value === values.status
                        )
                      : ""
                  }
                  onChange={(option) => setFieldValue("status", option.value)}
                />
                <ErrorMessage
                  name="status"
                  render={(errMsg) => (
                    <div className="errorMessage">{errMsg}</div>
                  )}
                />
              </div>

              {/* Render image upload section */}
              <div className="d-flex mt-4 upload-sec">
                <div className="upload-profile">
                  {!imageUrl && <div className="upload-profile-img">
                    <img
                      src={imageIcon}
                      alt="imageIcon"
                      className="upload-icon"
                    />
                    <p className="mt-2 mb-0 text-center">Upload Photo</p>
                  </div>}
                  <div className="uploaded-img-set">
                    <Field
                      name="logo_url"
                      type="file"
                      accept=".jpg , .png , .jpeg"
                      onChange={(event) => {
                        checkImageValidation(event.target.files[0]);
                      }}
                    />
                    {imageUrl && (
                      <div>
                        <FontAwesomeIcon
                          className="font-style"
                          icon={faCircleXmark}
                          onClick={checkImageSizeValidation}
                        />
                        <img
                          src={URL.createObjectURL(imageUrl)}
                          alt="select_photo"
                          className="uploaded-img"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Render error messages and buttons */}
              <div className="errorMessage mt-2">{imageError}</div>
              <div className="col-12">
                <div className="d-flex mt-4 flex-row-reverse">
                  <Button
                    variant="primary"
                    type="submit"
                    className="px-4 mx-1 add-button"
                  >
                    {loading && (
                      <Spinner
                        as="span"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    {loading ? " loading.... " : "Submit"}
                  </Button>
                  <Button
                    variant="outline-secondary"
                    className="px-4 mx-1"
                    onClick={() => navigate("/group")}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
            <ToastContainer />
          </FormikForm>
        );
      }}
    </Formik>
  );
};
