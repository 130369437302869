import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";

import { groupProfileApi } from "../../../../Services/CompanyApi";
import { Loader } from "../../../../Component/Loader/Loader";
import edit from "../../../../Assets/Images/edit_1.png";
import defaultImage from "../../../../Assets/Images/default-img.png"
import "./companyProfile.css";

export const CompanyProfile = () => {

  // Define state variables
  const navigate = useNavigate();
  const useParam = useParams();
  const [profileData, setProfileData] = useState();
  const [loading, setLoading] = useState(true);

  //Call company profile api
  useEffect(() => {
    groupProfileApi(useParam.group_id)
      .then((response) => {
        setProfileData(response.data.data[0]);
        setLoading(false);
      })
      .catch((errMsg) => console.log(errMsg));
  }, []);

  return (
    <>
      {loading ? (
        <div className="loader">
          <Loader />
        </div>
      ) : (
        <div className="inner-contain">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="d-flex">
                  <p
                    className="me-1"
                    onClick={() => navigate("/")}
                    style={{ cursor: "pointer" }}
                  >
                    Group
                  </p>
                  {"/"}
                  <p className="companyProfile-component mx-1">Group Profile</p>
                </div>
                <h4>Group Profile</h4>
              </div>
              {profileData && (
                <div className=" col-12 mt-2">
                  <div className=" bg-section">
                    <div className="mb-4">
                      <div className="cover-photo">
                        <div className="profile-header-contain">
                          <div className="profile-img">
                            {profileData?.logo_url ? <img src={profileData.logo_url} alt="logo" /> : <img src={defaultImage} alt="logo"/>}
                          </div>
                          <div className="profile-button w-100">
                            <h5 className="mx-3">{profileData.name}</h5>
                            <Button
                              className="edit-button"
                              onClick={() =>
                                navigate(
                                  `/group/editGroup/${useParam.group_id}`
                                )
                              }
                            >
                              <img src={edit} /> Update
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="profile-employee-info">
                      <h4>Information</h4>
                      <hr className="hr-border" />
                      <div className="d-flex mt-4">
                        <div className="col-6 col-md-4">
                          <label htmlFor="email" className="employee-label">
                            Email ID
                          </label>
                          <h6>{profileData.email}</h6>
                        </div>
                        <div className="col-6 col-md-4">
                          <label
                            htmlFor="mobile Number"
                            className="employee-label"
                          >
                            Mobile Number
                          </label>
                          <h6>{profileData.phone}</h6>
                        </div>
                        <div className="col-6 col-md-4">
                          <label htmlFor="address" className="employee-label">
                            Address
                          </label>
                          <h6>{profileData.address}</h6>
                        </div>
                      </div>
                      <div className="d-flex mt-3">
                        <div className="col-6 col-md-4">
                          <label htmlFor="status" className="employee-label">
                            Status
                          </label>
                          <h6>{profileData.status}</h6>
                        </div>
                        <div className="col-6 col-md-4">
                          <label
                            htmlFor="contact_person"
                            className="employee-label"
                          >
                            Contact Person
                          </label>
                          <h6>{profileData.contact_person_name}</h6>
                        </div>
                        <div className="col-6 col-md-4">
                          <label htmlFor="website" className="employee-label">
                            Website
                          </label>
                          <h6>{profileData.website_url}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
