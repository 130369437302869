import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { removeGroupApi } from "../../../Services/CompanyApi";
import { ToastContainer, toast } from "react-toastify";
import { fetchUserData, removeCompany } from "../../../Redux/companySlice";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import add from "../../../Assets/Images/add.png";
import dot from "../../../Assets/Images/threedot.png";
import edit from "../../../Assets/Images/edit.png";
import remove from "../../../Assets/Images/remove.png";
import call from "../../../Assets/Images/call.png";
import deleteIcon from "../../../Assets/Images/delete.png";
import email from "../../../Assets/Images/email.png";
import defaultImage from "../../../Assets/Images/default-img.png";
import "./company.css";
import { PopupModal } from "../../../Component/PopupModal/PopupModal";
import { Loader } from "../../../Component/Loader/Loader";
import searchIcon from "../../../Assets/Images/search.svg";

export const Company = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [companyId, setCompanyId] = useState("");
  const [companySearch, setCompanySearch] = useState("");
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  //Get user list
  useEffect(() => {
    dispatch(fetchUserData());
    setLoading(false);
  }, []);

  const userData = useSelector((state) => state.Company?.data);
  const handleShow = (company_id) => {
    setCompanyId(company_id);
    setShow(true);
  };

  //Remove company api
  const handleDelete = () => {
    removeGroupApi(companyId)
      .then((response) => {
        dispatch(removeCompany(companyId));
        toast.success(response.data.message);
        setShow(false);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
        setShow(false);
      });
  };

  const handleSearch = (event) => {
    if (event.target.value !== " ") {
      setCompanySearch(event.target.value);
    }
  };

  const filteredData =
    userData &&
    userData.filter((item) => {
      const cmp_name = item.name;
      return cmp_name.toLowerCase().includes(companySearch.toLowerCase());
    });

  return (
    <div className="inner-contain">
      <div className="container-fluid">
        <div className="row">
          <h4>Group</h4>
          <div className="header-container pb-4">
            <div>
              <InputGroup>
                <InputGroup.Text id="basic-addon1">
                  <img src={searchIcon} alt="search" />
                </InputGroup.Text>
                <Form.Control
                  type="search"
                  placeholder="Search Company"
                  aria-label="Recipient's username with two button addons"
                  value={companySearch}
                  onChange={handleSearch}
                />
              </InputGroup>
            </div>

            <Button
              variant="primary"
              className="px-3 add-company"
              onClick={() => navigate("/group/addGroup")}
            >
              <img src={add} alt="plus" className="mx-2" />
              <span>Add New Group</span>
            </Button>
          </div>
          {!loading ? (
            <>
              {filteredData.length !== 0 ? (
                filteredData.map((item) => {
                  return (
                    <div
                      className="col-12 col-md-6 col-lg-3 "
                      key={item.group_id}
                    >
                      <div
                        className="bg-white mb-3 rounded-3 cmp-grid"
                        onClick={() =>
                          navigate(`/group/groupProfile/${item.group_id}`)
                        }
                      >
                        <div className="p-4 py-3">
                          <div className="d-flex justify-content-between cmp-detail-card">
                            <div className="cmp-img">
                              {item.logo_url ? (
                                <img src={item.logo_url} alt="Logo" />
                              ) : (
                                <img src={defaultImage} alt="Logo" />
                              )}
                            </div>
                            <Dropdown onClick={(e) => e.stopPropagation()}>
                              <Dropdown.Toggle
                                className="bg-white p-2"
                                id="dropdown-basic"
                              >
                                <img src={dot} alt="dot" />
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="employee-delete-edit">
                                <Dropdown.Item
                                  onClick={() =>
                                    navigate(
                                      `/group/editGroup/${item.group_id}`
                                    )
                                  }
                                >
                                  <img src={edit} className="mb-1 me-1" />
                                  Update
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => handleShow(item.group_id)}
                                >
                                  <img src={remove} className="mb-1 me-1" />
                                  Remove
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          <h6 className="mt-2 text-truncate company-name">{item.name}</h6>
                          <p
                            className={
                              item.status === "Active"
                                ? "cmp-status-active-color"
                                : "cmp-status-inactive-color"
                            }
                          >
                            {item.status}
                          </p>
                          <hr className="hr-border" />
                          <div>
                            <div className="company-detail-box">
                              <img src={call} alt="call" />
                              <h6 className="m-0 ps-2 text-black-50">
                                {item.phone}
                              </h6>
                            </div>
                            <div className="company-detail-box">
                              <img src={email} alt="email" />
                              <h6 className="m-0 ps-2 text-black-50">
                                {item.email}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="loader">
                  Company not found
                </div>
              )}
            </>
          ) : (
            <div className="loader">
              <Loader />
            </div>
          )}
          <ToastContainer />
        </div>
      </div>
      {show && (
        <PopupModal
          show={show}
          modalTitle={"Remove Group"}
          description={"Are you sure you want to remove group"}
          handleClose={() => setShow(false)}
          button1={"Cancel"}
          button2={"Remove"}
          photo={deleteIcon}
          handleDelete={handleDelete}
        />
      )}
    </div>
  );
};
