import { createBrowserRouter } from "react-router-dom";
import { Login } from "../Pages/Auth/Login/Login";
import { Dashboard } from "../Pages/Dashboard";
import { Company } from "../Pages/Dashboard/Company/Company";
import { AddCompany } from "../Pages/Dashboard/Company/AddCompany/AddCompany";
import { EditCompany } from "../Pages/Dashboard/Company/EditCompany/EditCompany";
import { CompanyProfile } from "../Pages/Dashboard/Company/CompanyProfile/CompanyProfile";
import { AuthProtected } from "./AuthProtected/AuthProtected";
import { DashboardProtected } from "./DashboardProtected/DashboardProtected";
export const router = createBrowserRouter([
  {
    element: <AuthProtected />,
    children: [
      {
        path: "/",
        element: <Login />,
      },
    ],
  },
  {
    element: <DashboardProtected />,
    children: [
      {
        element: <Dashboard />,
        children: [
          {
            path: "group",
            element: <Company />,
          },
          {
            path: "group/addGroup",
            element: <AddCompany />,
          },
          {
            path: "group/editGroup/:group_id",
            element: <EditCompany />,
          },
          {
            path: "group/groupProfile/:group_id",
            element: <CompanyProfile />,
          },
        ],
      },
    ],
  },
]);
